<template>
  <div class="hold-transition">
    <div class="modal fade"
      id="modal-form-export"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Generar Reporte Solicitudes</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-0 pt-1">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Filtros"
                  data-toggle="tab"
                  href="#Filtros"
                  >Filtros
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade active show" id="Filtros">
                <div class="card-body">
                  <div class="row">
                    <div class="form-group col-md-6">
                      <label>Fecha Inicial</label>
                      <input v-model="filtros.fecha_ini"
                        type="date"
                        class="form-control form-control-sm"
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label>Fecha Final</label>
                      <input v-model="filtros.fecha_fin"
                        type="date"
                        class="form-control form-control-sm"
                      />
                    </div>
                    <div class="form-group col-md-12">
                      <label>Tipo Solicitud</label>
                      <select
                        class="form-control form-control-sm"
                        v-model="filtros.tipo_solicitud"
                      >
                        <option value="">Seleccione...</option>
                        <option
                          v-for="tipo_solicitud in $parent.listasForms.tipo_solicitudes"
                          :key="tipo_solicitud.numeracion"
                          :value="tipo_solicitud.numeracion"
                        >
                          {{ tipo_solicitud.descripcion }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group col-md-12">
                      <label>Material Inventarios</label>
                      <select
                        class="form-control form-control-sm"
                        v-model="filtros.material_inventarios"
                      >
                        <option value="">Seleccione...</option>
                        <option
                          v-for="mat in $parent.listasForms.material_inventario"
                          :key="mat.id"
                          :value="mat.id"
                        >
                          {{ mat.descripcion }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group col-md-12">
                      <label>Usuario Solicita</label>
                      <v-select
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="filtros.usuario"
                        placeholder="Usuario"
                        label="name"
                        :options="listasForms.usuarios"
                        @search="buscarUsuarios"
                      >
                      </v-select>
                    </div>
                    <div class="col-md-6">
                      <label>Tipo Emergencia</label>
                      <select
                        class="form-control form-control-sm"
                        v-model="filtros.tipo_emergencia"
                      >
                        <option value="">Seleccione...</option>
                        <option
                          v-for="tipo_emergencia in $parent.listasForms.tipo_emergencias"
                          :key="tipo_emergencia.numeracion"
                          :value="tipo_emergencia.numeracion"
                        >
                          {{ tipo_emergencia.descripcion }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-6">
                      <label>Estado</label>
                      <select
                        class="form-control form-control-sm"
                        v-model="filtros.estado"
                      >
                        <option value="">Seleccione...</option>
                        <option
                          v-for="estado in $parent.listasForms.estados"
                          :key="estado.numeracion"
                          :value="estado.numeracion"
                        >
                          {{ estado.descripcion }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <div>
              <button
                type="button"
                class="btn btn-sm bg-navy"
                @click="generarListadoExcel()"
              >
                Generar Listado
                <i class="fas fa-file-download"> </i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";

export default {
  name: "CsSolicitudExport",
  components: {
    vSelect,
  },
  data() {
    return {
      filtros: {
        fecha_ini: null,
        fecha_fin: null,
        tipo_solicitud: null,
        material_inventarios: null,
        usuario: null,
        tipo_emergencia: null,
        estado: null,
      },
      listasForms: {
        usuarios: [],
      }
    };
  },

  methods: {
    resetFiltros(){
      this.filtros = {
        fecha_ini: null,
        fecha_fin: null,
        tipo_solicitud: null,
        material_inventarios: null,
        usuario: null,
        tipo_emergencia: null,
        estado: null,
      };
    },

    // Métodos para los selects
    buscarUsuarios(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/usuarios/lista?name=" + search;
        axios
          .get(url)
          .then(function(response) {
            let respuesta = response.data;
            me.listasForms.usuarios = respuesta;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrió un error: " + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            loading(false);
          });
      }
    },

    // Métodos para acciones
    generarListadoExcel() {
      this.$parent.cargando = true;
      this.filtros.user_id = this.filtros.usuario ? this.filtros.usuario.id : null;

      axios({
        method: "POST",
        url: "/api/cs/solicitudesNacionales/reporte",
        data: this.filtros,
      })
        .then((response) => {
          let data = response.data;
          if (!data.error) {
            this.$swal({
              icon: "success",
              title: data.message,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            location.href = data.url;
          } else {
            this.$swal({
              icon: "error",
              title: data.error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
          this.$parent.cargando = false;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
        });
    },
  },
};
</script>
